import React, { useEffect } from 'react'
import Banner from '../commonComponets/Banner'
import HelpScreen from '../componets/help/HelpScreen'
import ContactUsForm from '../componets/contactus/ContactUsForm'

const Help = () => {


    useEffect(() => {

        if ((window as any).Tawk_API) {
            (window as any).Tawk_API?.showWidget()
        } else {
            var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
            (function () {
                var s1 = document.createElement("script");
                var s0: any = document.getElementsByTagName("script")[0];
                s1.async = true;
                s1.src = 'https://embed.tawk.to/60c3840b65b7290ac6357a89/1f7trnjq7';
                s1.charset = 'UTF-8';
                s1.setAttribute('crossorigin', '*');
                s0.parentNode.insertBefore(s1, s0);
            })();
        }


        return () => {
            // console.log(Tawk_API)
            (window as any).Tawk_API?.hideWidget()
        }
    }, [])
    // console.log(window)
    return (
        <>
            <Banner title="Help" />
            <HelpScreen />
            <ContactUsForm />
        </>
    )
}

export default Help
