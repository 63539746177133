export const otherPurple = "rgb(72, 15, 143)";
export const basePurple = "rgb(69, 40, 150)";
export const baseOrange = "rgb(255, 121, 6)";
export const baseGreen = "rgb(0, 180, 55)";
export const grey = "rgb(128, 128, 128)";
export const black = "rgb(0, 0, 0)";
export const white = "rgb(255, 255, 255)";

console.log("Host Is : ", window.location.hostname)

// export const Endpoint = window.location.hostname === "https://kenan-web.herokuapp.com" ? "https://kenan-apis.herokuapp.com" : window.location.hostname === "http://18.224.214.69:4000" ? "http://18.224.214.69:3000" : ""

// export const Endpoint = "http://localhost:4000";
export const Endpoint = "https://backend.futuresol.com.au";
export const END_POINT_IP = "http://3.133.96.193"




export const AndroidApp = "https://play.google.com/store/apps/details?id=com.delivery.kenancourier.driver"