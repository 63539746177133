import React from 'react'
import Banner from '../commonComponets/Banner'
import Payments from '../componets/admin/payments/Payments'

const Payment = () => {
    return (
        <>
        <Banner title="Payment"/>
        <Payments/>
        <div className="m-5"></div>
        </>
    )
}

export default Payment
