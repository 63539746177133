import React from 'react'
import Banner from '../commonComponets/Banner'
import DriveWithMeScreen from '../componets/driveWithMe/DriveWithMeScreen'

const DriveWithMe = () => {
    return (
        <>
        <Banner title="Drive With me" />
        <DriveWithMeScreen/>
        </>
    )
}

export default DriveWithMe
