import React, { useState } from 'react'
import Styled from "styled-components";
import { CustomButton } from '../../commonComponets'
import { baseOrange, basePurple, white } from '../../constants'
import { Link, Redirect } from "react-router-dom"
import { useHistory } from "react-router-dom"
import * as EmailValidator from 'email-validator';
import { CircularProgress } from '@material-ui/core';
import * as Actions from "../../store/actions"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store/root.reducer";

const StyledAnchor = Styled.a`
    color:${basePurple};

    &:hover{
        color:${baseOrange};
        cursor:pointer;
    }
`

const LoginForm: React.FC = (props) => {


    const history = useHistory()
    const dispatch = useDispatch()


    const [Email, setEmail] = useState<string>("");
    const [Password, setPassword] = useState<string>("");
    const [ErrorMsg, setErrorMsg] = useState<string>("");
    const [IsError, setIsError] = useState<boolean>(false);
    const [IsLoading, setIsLoading] = useState<boolean>(false);

    const IsLogin = useSelector((state: RootState) => state.User.IsLogin)

    async function submitHandler(): Promise<void> {
        setIsError(false)
        setErrorMsg("")
        if (IsLoading) return;
        try {
            if (!Email || !Password) throw "Please fill all the required fields"
            if (!EmailValidator.validate(Email)) throw "Please enter a valid email address"

            setIsLoading(true)
            const res = await dispatch(Actions.LoginAction(Email, Password))
            if (res) throw res
        } catch (error) {
            console.log(error)
            setIsError(true);
            setErrorMsg(error)
            setIsLoading(false)

        }
        // history.push("/admin/user")
    }
    if (IsLogin) return <Redirect to="/admin/user" />
    return (
        <>
            <div className="container login-form">
                <div className="row display-flex justify-content-center ">
                    <div className="col-lg-8 text-center ">
                        {/* <h1>Login</h1> */}
                        <div className="form-group mt-5 ">
                            <input
                                style={{ height: "54px" }}
                                type="email"
                                placeholder="Enter Email *"
                                required
                                className={`form-control ${IsError && !Email ? "errorInput" : ""}`}
                                value={Email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-4">
                            <input
                                style={{ height: "54px" }}
                                type="password"
                                placeholder="Enter Password *"
                                required
                                className={`form-control ${IsError && !Password ? "errorInput" : ""}`}
                                value={Password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <p className="text-danger  pt-2">{ErrorMsg}</p>
                        {
                            IsLoading ? <CircularProgress /> : (
                                <CustomButton
                                    varient="solid"
                                    className="mt-4"
                                    backgorundColor={basePurple}
                                    width="100%"
                                    height="50px"
                                    hoverbackgroundColor={baseOrange}
                                    styles={{ fontSize: "18px", fontWeight: "bold" }}
                                    noFilter
                                    onClick={() => submitHandler()}
                                >
                                    LOGIN
                                </CustomButton>
                            )
                        }
                        <p className="mt-4">
                            Don't have an account? &nbsp;
                       <StyledAnchor onClick={() => history.push("/signup")}> Sign Up</StyledAnchor> &nbsp; &nbsp;
                          <StyledAnchor onClick={() => history.push("/forgotpassword")}>Forgot Password?</StyledAnchor>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginForm
