import React from 'react'
import Banner from '../commonComponets/Banner'
import SelectRider from '../componets/admin/selectRider/SelectRider'

const SelectRiderSimple = () => {
    return (
        <div style={{height:"auto"}}>
        <Banner title="Select Rider" />
        <SelectRider/>
        </div>
    )
}

export default SelectRiderSimple
