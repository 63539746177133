import React from 'react'
import Banner from '../commonComponets/Banner'
import BookingScreen from '../componets/booking/BookingScreen'
import BookingsListScreen from '../componets/booking/BookingsListScreen'
import BookingsPolicyScreen from '../componets/booking/BookingsPolicyscreen'

const Bookings = () => {
    return (
        <>
            <Banner title="Bookings" />
            <BookingsPolicyScreen/>
            <BookingsListScreen/>
        </>
    )
}

export default Bookings
