import React, { useEffect } from 'react'
import Banner from '../commonComponets/Banner'
import ContactListsScreen from '../componets/contactus/ContactListsScreen'
import ContactUsScreen from '../componets/contactus/ContactUsScreen'

const ContactUs: React.FC = (props) => {
    useEffect(()=>{
        // //document.title = "Contact Us"
    },[])
    return (
        <>
            <Banner title="Contact Us" />
            <ContactListsScreen />
            {/* <ContactUsScreen/> */}
        </>
    )
}

export default ContactUs
