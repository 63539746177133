import Axios from "axios"
import { AppThunk, RootState } from "../root.reducer";
import * as ReducerActions from "../reducers"
import axios from "axios";
import { BookingObjINterface, BookingResponse, GetBookingResponse } from "../../interfaces";
import { Endpoint } from "../../constants";
import { toast } from "react-toastify";


export const MakeAbooking = (bookingBody: any): AppThunk<Promise<{ ErrorMsg: string } | null | undefined>> => async (dispatch, getState: () => RootState) => {

    try {

        const res = await Axios.post<BookingResponse>(`${Endpoint}/api/v1/bookings/create`, bookingBody);

        dispatch(ReducerActions.SaveBookingOnGoing({ booking: res.data.Booking }))

        return null
    } catch (error) {
        console.log(error.response?.data.Msg)
        return error.response?.data.Msg
    }
};

export const MakePayment = (nonce: string, DriverTip: any, cardData: any): AppThunk<Promise<{ ErrorMsg: string } | null | undefined>> => async (dispatch, getState: () => RootState) => {

    try {
        const body = {
            "Nonce": nonce,
            "BookingId": getState().Booking.OnGoingBookning?._id,
            DriverTip,
            cardType:cardData.card_brand,
        }
        const res = await Axios.post<BookingResponse>(`${Endpoint}/api/v1/bookings/makePayment`, body);

        // dispatch(ReducerActions.SaveBookingOnGoing({ booking: res.data.Booking }))
        // dispatch(ReducerActions.SaveBookingOnGoing({ booking: undefined}))

        return null
    } catch (error) {
        // console.log(error.response?.data.Msg)
        // console.log(typeof error.response?.data.Msg)
        if (typeof error.response?.data.Msg === "string") {
            const json = JSON.parse(error.response?.data.Msg);
            // console.log(json)
            return json.errors[0].code == "GENERIC_DECLINE" ? "Your card has been declined!" : json.errors[0].code
        }
        return error.response?.data.Msg
    }
};


export const GetBookings = (size: number = 25): AppThunk<Promise<{ ErrorMsg: string } | null | undefined>> => async (dispatch, getState: () => RootState) => {

    try {
        dispatch(ReducerActions.GettingAllBookings({ booking: { Bookings: [], Error: false, Msg: "", RecordsFound: 0, TotalPages: 0, TotalRecords: 0, Exception: null } }))
        const res = await Axios.get<GetBookingResponse>(`${Endpoint}/api/v1/bookings/getBookings?Page=1&Size=${size}&UserID=${getState().User?.UserData?._id || ""}`, {
            headers: {
                "x-auth-token": getState().User.Token
            }
        });

        dispatch(ReducerActions.GettingAllBookings({ booking: res.data }))

        return null
    } catch (error) {
        console.log(error.response?.data.Msg)
        return null
    }
};

export const GetOneBookings = (id: string | number): AppThunk<Promise<{ ErrorMsg: string } | null | undefined>> => async (dispatch, getState: () => RootState) => {

    try {
        dispatch(ReducerActions.GettingAllBookings({ booking: { Bookings: [], Error: false, Msg: "", RecordsFound: 0, TotalPages: 0, TotalRecords: 0, Exception: null } }))
        const res = await Axios.get<GetBookingResponse>(`${Endpoint}/api/v1/bookings/getBookings?BookingId=${id}`, {
            headers: {
                "x-auth-token": getState().User.Token
            }
        });

        return res?.data

    } catch (error) {
        console.log(error.response?.data.Msg)
        return error.response?.data
    }
};