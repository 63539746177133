import Axios from "axios"
import { AppThunk, RootState } from "../root.reducer";
import * as ReducerActions from "../reducers"
import axios from "axios";
import { loginRequestInterface, RequestResetPassCodeInterface, UpdateProfileResponse, verifyTokenResponseInterface } from "../../interfaces";
import { Endpoint } from "../../constants";
import { toast } from "react-toastify"



// export const dummyAction = (dummyPayload: boolean): AppThunk => (dispatch, getState: () => RootState) => {
//     try {


//     } catch (error) {
//         console.log(error)
//     }
// };

export const Logout = (): AppThunk => (dispatch, getState: () => RootState) => {
    try {

        dispatch(ReducerActions.Logout())

    } catch (error) {
        console.log(error)
    }
};


export const LoginAction = (Email: string, Password: string): AppThunk<Promise<{ ErrorMsg: string } | null | undefined>> => async (dispatch, getState: () => RootState) => {
    try {

        const body = {
            Email,
            Password
        }

        const res = await Axios.post<loginRequestInterface>(`${Endpoint}/api/v1/user/simple/login`, body);

        dispatch(ReducerActions.LoginSuccess({ UserData: res.data.User, Token: res.data.Token }))
        localStorage.setItem("@Token", res.data.Token)
        return null
    } catch (error) {
        console.log(error.response.data.Msg)
        return error.response?.data.Msg
    }
};


export const RegisterAction = (FirstName: string, LastName: string, Email: string, PhoneNo: string, Password: string, Address: string, history?: any, location?: any): AppThunk<Promise<{ ErrorMsg: string } | null | undefined>> => async (dispatch, getState: () => RootState) => {
    try {

        const body = {
            FirstName,
            LastName,
            Email,
            PhoneNo,
            Password,
            Address
        }

        const res = await Axios.post<loginRequestInterface>(`${Endpoint}/api/v1/user/create`, body);


        const query = new URLSearchParams(location.search)

        // if (query.has("redirect")) {
        //     history.push("/bookings");
        // }

        dispatch(ReducerActions.LoginSuccess({ UserData: res.data.CreatedUser, Token: res.data.Token }))
        localStorage.setItem("@Token", res.data.Token)
        return null
    } catch (error) {
        console.log(error.response.data.Msg)
        return error.response?.data.Msg
    }
};

export const VerifyTokenAction = (): AppThunk => async (dispatch, getState: () => RootState) => {
    try {

        const res = await Axios.get<verifyTokenResponseInterface>(`${Endpoint}/api/v1/user/simple/verifyToken`, {
            headers: {
                "x-auth-token": getState().User.Token
            }
        });

        dispatch(ReducerActions.VerifyToken({ Token: res.data.newToken, UserData: res.data.User }))

        localStorage.setItem("@Token", res.data.newToken)
    } catch (error) {
        console.log(error.response?.data?.Msg)
        dispatch(ReducerActions.Logout())

    }
};

export const RequestResetPassword = (Email: string,): AppThunk<Promise<{ ErrorMsg: string } | null | undefined>> => async (dispatch, getState: () => RootState) => {
    try {

        const body = {
            Email,
        }

        const res = await Axios.post<RequestResetPassCodeInterface>(`${Endpoint}/api/v1/user/requestPassResetCode`, body);

        if (!res.data.Error) return

    } catch (error) {
        console.log(error.response?.data.Msg)
        return error.response?.data.Msg
    }
};

export const ResetPassword = (Email: string, Code: string, Password: string): AppThunk<Promise<{ ErrorMsg: string } | null | undefined>> => async (dispatch, getState: () => RootState) => {
    try {

        const body = {
            Email,
            Code,
            Password
        }

        const res = await Axios.post<RequestResetPassCodeInterface>(`${Endpoint}/api/v1/user/resetPassword`, body);

        if (!res.data.Error) return

    } catch (error) {
        console.log(error.response?.data.Msg)
        return error.response?.data.Msg
    }
};

export const ChangePassword = (OldPassword: string, NewPassword: string,): AppThunk<Promise<{ ErrorMsg: string } | null | undefined>> => async (dispatch, getState: () => RootState) => {
    try {

        const body = {
            OldPassword,
            NewPassword
        }

        const res = await Axios.put<RequestResetPassCodeInterface>(`${Endpoint}/api/v1/user/changePassword`, body, {
            headers: {
                "x-auth-token": getState().User.Token
            }
        });

        if (!res.data.Error) return

    } catch (error) {
        console.log(error.response?.data.Msg)
        return error.response?.data.Msg
    }
};



export const UpdateProfile = (FirstName: string, LastName: string, Email: string, PhoneNo: string, Address: string): AppThunk<Promise<{ ErrorMsg: string } | null | undefined>> => async (dispatch, getState: () => RootState) => {
    try {

        const body = {
            FirstName,
            LastName,
            Email,
            PhoneNo,
            Address
        }

        const res = await Axios.put<UpdateProfileResponse>(`${Endpoint}/api/v1/user/profileUpdate`, body, {
            headers: {
                "x-auth-token": getState().User.Token
            }
        });
        dispatch(ReducerActions.UpdateUserProfile({ UserData: res.data.User }))
        if (!res.data.Error) return

    } catch (error) {
        console.log(error.response?.data.Msg)
        return error.response?.data.Msg
    }
};


export const RegisterDriver = (
    Email: any,
    Password: any,
    Name: any,
    VehicalType: any,
    DriverAddress: any,
    ContactNumber: any,
    DateOfBirth: any,
    ProofOfWorkEligibility: any,
    ProfilePicture: any,
    DriverLicense: any,
    VehicalInsurance: any,
    VehicleRegisteration: any,
    VehicleInspection: any,
    AggreeToTerms: any,
): AppThunk<Promise<{ ErrorMsg: string } | null | undefined>> => async (dispatch, getState: () => RootState) => {
    try {
        const NewFormData = new FormData();

        NewFormData.append("Email", Email)
        NewFormData.append("Password", Password)
        NewFormData.append("Name", Name)
        NewFormData.append("VechicleType", VehicalType)
        NewFormData.append("DriverAddress", DriverAddress)
        NewFormData.append("ContactNumber", ContactNumber)
        NewFormData.append("DateOfBirth", DateOfBirth)
        NewFormData.append("ProofOfWorkEligibility", ProofOfWorkEligibility)
        NewFormData.append("ProfilePicture", ProfilePicture)
        NewFormData.append("DriverLicense", DriverLicense)
        NewFormData.append("VehicalInsurance", VehicalInsurance)
        NewFormData.append("VehicleRegisteration", VehicleRegisteration)
        NewFormData.append("VehicleInspection", VehicleInspection)
        NewFormData.append("AggreeToTerms", AggreeToTerms)



        const res = await Axios.post(`${Endpoint}/api/v1/Driver/signup`, NewFormData);

        return null
    } catch (error) {
        console.log(error.response.data.Msg)
        return error.response?.data.Msg
    }
};


export const Contactus = (Name: string, Email: string, Subject: string, Desc: string, PhoneNo: string): AppThunk => async (dispatch, getState: () => RootState) => {
    try {

        const body = {
            Name,
            Email,
            Subject,
            Desc,
            PhoneNo,
        }

        const res = await Axios.post<any>(`${Endpoint}/api/v1/EmailTemplate/contactUs`, body);
        toast.success(res.data.Msg)
        return true
    } catch (error) {
        toast.error(error.response?.data.Msg)

        return false
    }
};
