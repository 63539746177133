import React, { useEffect, useState, useMemo } from 'react'
import { MapWithDirectionsInterface } from "../interfaces"
import { GoogleMap, Marker, withGoogleMap, DirectionsRenderer } from "react-google-maps"


const MapWithDirections: React.FC<MapWithDirectionsInterface> = (props) => {

    const [Directions, setDirections] = React.useState<any>(null)

    useEffect(() => {
        myDirections()
    }, [props.origin, props.destination])

    async function myDirections() {
        if (props.origin?.lat == 0 && props.origin?.long == 0 || props.destination?.lat == 0 && props.destination?.long == 0) return
        const directionsService = new window.google.maps.DirectionsService();

        const origin = { lat: props.origin?.lat || 0, lng: props.origin?.long || 0 };
        const destination = { lat: props.destination?.lat || 0, lng: props.destination?.long || 0 };

        directionsService.route(
            {
                origin: origin,
                destination: destination,
                travelMode: window.google.maps.TravelMode.DRIVING
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    setDirections(result)
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            }
        );
    }

    const MyMap = withGoogleMap(() => <GoogleMap
        defaultZoom={4}
        defaultCenter={{
            lat: 51.213890,
            lng: -102.462776
        }}
    >
        {
            Directions ? <DirectionsRenderer
                directions={Directions}
            /> : null
        }
        {
            !Directions ? (
                <>
                    <Marker position={{ lat: props.origin?.lat || 0, lng: props.origin?.long || 0 }} />
                    <Marker position={{ lat: props.destination?.lat || 0, lng: props.destination?.long || 0 }} />
                </>
            ) : null
        }
        {
            props.DriverLocation ? (
                <Marker
                    icon={{

                        url: props.DriverType === "SUV" ? '/DriverPointerSUV.png' : props.DriverType === "Sedan" ? '/DriverPointerSedan.png' :  props.DriverType === "Van" ? '/DriverPointerVan.png' :'/DriverPointer.png' ,

                        anchor: new google.maps.Point(17, 46),

                        scaledSize: new google.maps.Size(37, 37)

                    }}
                    // icon={"../assets/icons/DriverPointer.png"}
                    position={{ lat: props.DriverLocation?.latitude || 0, lng: props.DriverLocation?.longitude || 0 }}
                />

            ) : null
        }
    </GoogleMap>)
    const MyNewMapComp = useMemo(() => {
        return <MyMap
            containerElement={<div style={props.containerStyle ? props.containerStyle : {}} />}
            mapElement={<div style={{ height: `100%` }} />}

        />
    }, [props.origin, props.destination, Directions])
    return (
        <>
            {
                MyNewMapComp
            }
        </>
    )
}

export default MapWithDirections
//use this componet after memoization
