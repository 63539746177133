import React, { useEffect } from 'react'
import Banner from '../commonComponets/Banner'
import TermsAndConditionsScreen from '../componets/termsAndContions/TermsAndConditionsScreen'

const TermsAndConditions:React.FC = () => {


    useEffect(()=>{
        //document.title = "Terms & Conditions"
    },[])

    return (
        <>
            <Banner title="Terms And Conditions" />
            <TermsAndConditionsScreen/>
        </>
    )
}

export default TermsAndConditions
