import React from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'

const GooglePlacesCommon: React.FC<{
    placeholder: string,
    value: string,
    setValue: any,
    IsError?: boolean,
    onSelect?: any,
}> = (props) => {
    return (
        <PlacesAutocomplete
            searchOptions={
                {
                    componentRestrictions: { country: ['CA'] },
                }
            }
            value={props.value}
            onChange={(address: any) => props.setValue(address)}
            onSelect={(address: any) => {
                 if(props.onSelect){
                    props.onSelect(address)
                 }
                props.setValue(address)
            }}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className={`form-group ${props.IsError ? " errorInput" : ""}`}>
                    <input

                        className="form-control"

                        {...getInputProps({
                            placeholder: props.placeholder,
                            className: 'location-search-input form-control',
                        })}
                        style={{ height: "54px" }}
                    />
                    <div className="autocomplete-dropdown-container" style={{ position: "absolute", zIndex: 10000, width: "100%", background: "white", }}>
                        {loading && <div></div>}
                        {suggestions.map(suggestion => {
                            const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer', marginTop: "5px" }
                                : { backgroundColor: '#ffffff', cursor: 'pointer', marginTop: "5px" };
                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>

    )
}

export default GooglePlacesCommon
