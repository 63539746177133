import React from 'react'
import Banner from '../commonComponets/Banner'
import { CareersScreen } from '../componets/careers/CareersScreen'

export const Careers: React.FC = () => {
    return (
        <>
            <Banner title="Careers" />
            <CareersScreen />
        </>
    )
}
