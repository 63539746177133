import React, { useEffect } from 'react'
import Banner from '../commonComponets/Banner'
import ServicesScreen from '../componets/services/ServicesScreen'

const Services: React.FC = (props) => {


  useEffect(()=>{
    //document.title = "Services"
},[])
    return (
        <>
          <Banner title="Services" />
          <ServicesScreen/>
        </>
    )
}

export default Services
