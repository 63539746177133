import React from 'react'
import Banner from '../commonComponets/Banner'
import { RequirementsScreen } from '../componets/requirements/RequirementsScreen'

export const Requirements: React.FC = () => {
    return (
        <>
            <Banner title="Requirments" />
            <RequirementsScreen />
        </>
    )
}
