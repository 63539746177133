import React, { useEffect, useState } from 'react'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    Button
} from 'reactstrap';
import logo from "../../assets/images/newLogo1.jpg"
import { ReactComponent as X } from "../../assets/images/x.svg"
import { ReactComponent as User } from "../../assets/icons/user.svg"
import { ReactComponent as Bars } from "../../assets/images/menu.svg"
import { Link } from "react-router-dom"
import { CustomButton } from "../../commonComponets"
import { useHistory, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store/root.reducer";
import { useRealTimeDeviceDetector } from "../../helpers/useRealTimeDeviceDetector"
import Popover from '@material-ui/core/Popover';

const NavBarWeb: React.FC = () => {

    const { IsMob, IsTab, IsWeb } = useRealTimeDeviceDetector()
    const history = useHistory()
    const location = useLocation()

    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);



    const toggle = () => setIsOpen(!isOpen);

    const IsLogin = useSelector((state: RootState) => state.User.IsLogin)
    const NavbarCOntent = useSelector((state: RootState) => state.Content.NavbarCOntent)

    useEffect(() => {
        setIsOpen(false)
    }, [location.pathname])


    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);


    if (location.pathname.split("/").includes("admin")) {
        return <></>
    }


    return (
        <nav className="NewNavBar" style={{ background: NavbarCOntent?.BackgroundColor }}>
            <img onClick={() => history.push("/")} src={NavbarCOntent?.Logo} style={{ cursor: "pointer", }} />

            <div className="navLeft">
                {
                    IsWeb ? (
                        <>
                            <Link  style={{fontSize:"15px", color: NavbarCOntent?.FontColor, fontWeight: "bolder", display: !NavbarCOntent?.Menu1Text ? "none" : "" }} className="NavLinksForNavBar" to={NavbarCOntent?.Menu1Route || "/"} >{NavbarCOntent?.Menu1Text}</Link>
                            <Link style={{fontSize:"15px", color: NavbarCOntent?.FontColor, fontWeight: "bolder", display: !NavbarCOntent?.Menu2Text ? "none" : "" }} className="NavLinksForNavBar" to={NavbarCOntent?.Menu2Route || "/"} >{NavbarCOntent?.Menu2Text}</Link>
                            <Link style={{fontSize:"15px", color: NavbarCOntent?.FontColor, fontWeight: "bolder", display: !NavbarCOntent?.Menu3Text ? "none" : "" }} className="NavLinksForNavBar" to={NavbarCOntent?.Menu3Route || "/"} >{NavbarCOntent?.Menu3Text}</Link>
                            <Link style={{fontSize:"15px", color: NavbarCOntent?.FontColor, fontWeight: "bolder", display: !NavbarCOntent?.Menu4Text ? "none" : "" }} className="NavLinksForNavBar" to={NavbarCOntent?.Menu4Route || "/"} >{NavbarCOntent?.Menu4Text}</Link>
                            <Link style={{fontSize:"15px", color: NavbarCOntent?.FontColor, fontWeight: "bolder", display: !NavbarCOntent?.Menu5Text ? "none" : "" }} className="NavLinksForNavBar" to={NavbarCOntent?.Menu5Route || "/"} >{NavbarCOntent?.Menu5Text}</Link>
                            <Link style={{fontSize:"15px", color: NavbarCOntent?.FontColor, fontWeight: "bolder", display: !NavbarCOntent?.Menu6Text ? "none" : "" }} className="NavLinksForNavBar" to={NavbarCOntent?.Menu6Route || "/"} >{NavbarCOntent?.Menu6Text}</Link>
                            {/* <Link style={{ fontWeight: "bolder" }} className="NavLinksForNavBar" to="/bookings">BOOKINGS</Link> */}
                        </>
                    ) : null
                }

            </div>
            <div className="navRight">
                {
                    IsLogin ? (
                        <button
                            style={{ color: NavbarCOntent?.FontColor }}
                            onClick={(e) => {
                                history.push("/signup")
                                setIsOpen(false)
                            }}
                            className="DashboardButton">
                            Go To Dashboard
                        </button>
                    ) : (
                            <>
                                <button
                                    style={{ color: NavbarCOntent?.FontColor, }}
                                    onClick={() => {
                                        history.push("/login")
                                        setIsOpen(false)
                                    }}
                                    className="loginButton">
                                    {/* <img src={User} style={{ height: "17px",stroke:NavbarCOntent?.FontColor }} /> */}

                                    <User  height="17px" stroke={NavbarCOntent?.FontColor} />
                                    {
                                        IsWeb ? NavbarCOntent?.LoginButtonText : ""
                                    }
                                </button>
                                <button
                                    style={{ color: NavbarCOntent?.FontColor }}
                                    aria-describedby={"PopUp"}
                                    onClick={handleClick}
                                    className="signUpButton">
                                    {NavbarCOntent?.SignUpButtonText}
                                </button>
                                <Popover
                                    style={{ marginTop: "10px" }}
                                    id={"PopUp"}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <ul className="signUpDropDown">
                                        <li
                                            onClick={() => {
                                                history.push(NavbarCOntent?.DropDownOptionOneRoute || "/")
                                                handleClose()
                                            }}
                                        > {NavbarCOntent?.DropDownOptionOneText} </li>
                                        <li
                                            onClick={() => {
                                                history.push(NavbarCOntent?.DropDownOptionTwoRoute || "/")
                                                handleClose()
                                            }}
                                        > {NavbarCOntent?.DropDownOptionTwoText} </li>
                                    </ul>
                                </Popover>
                            </>
                        )
                }
                {
                    !IsWeb ? (
                        <>
                            {
                                !isOpen ? (
                                    // <img onClick={() => setIsOpen(true)} className="p-2 " src={Bars} />
                                    <Bars height="40px" width="40px" stroke={NavbarCOntent?.FontColor}  onClick={() => setIsOpen(true)} className="p-2 " />
                                ) : (
                                        // <img onClick={() => setIsOpen(false)} className="p-2 " src={X} />
                                        <X height="40px" width="40px"  stroke={NavbarCOntent?.FontColor} onClick={() => setIsOpen(false)} className="p-2 "/>
                                    )
                            }
                        </>
                    ) : null
                }
            </div >
            {
                !IsWeb ? (
                    <div className="ResponsiveMenu" style={{ height: isOpen ? "50vh" : "0px",backgroundColor:NavbarCOntent?.BackgroundColor }}>
                        <Link style={{color:NavbarCOntent?.FontColor, fontWeight: "bolder", display: !NavbarCOntent?.Menu1Text ? "none" : "" }} className="NavLinksForNavBar" to={NavbarCOntent?.Menu1Route || "/"} >{NavbarCOntent?.Menu1Text}</Link>
                        <Link style={{color:NavbarCOntent?.FontColor, fontWeight: "bolder", display: !NavbarCOntent?.Menu2Text ? "none" : "" }} className="NavLinksForNavBar" to={NavbarCOntent?.Menu2Route || "/"} >{NavbarCOntent?.Menu2Text}</Link>
                        <Link style={{color:NavbarCOntent?.FontColor, fontWeight: "bolder", display: !NavbarCOntent?.Menu3Text ? "none" : "" }} className="NavLinksForNavBar" to={NavbarCOntent?.Menu3Route || "/"} >{NavbarCOntent?.Menu3Text}</Link>
                        <Link style={{color:NavbarCOntent?.FontColor, fontWeight: "bolder", display: !NavbarCOntent?.Menu4Text ? "none" : "" }} className="NavLinksForNavBar" to={NavbarCOntent?.Menu4Route || "/"} >{NavbarCOntent?.Menu4Text}</Link>
                        <Link style={{color:NavbarCOntent?.FontColor, fontWeight: "bolder", display: !NavbarCOntent?.Menu5Text ? "none" : "" }} className="NavLinksForNavBar" to={NavbarCOntent?.Menu5Route || "/"} >{NavbarCOntent?.Menu5Text}</Link>
                        <Link style={{color:NavbarCOntent?.FontColor, fontWeight: "bolder", display: !NavbarCOntent?.Menu6Text ? "none" : "" }} className="NavLinksForNavBar" to={NavbarCOntent?.Menu6Route || "/"} >{NavbarCOntent?.Menu6Text}</Link>

                        {/* <Link style={{ fontWeight: "bolder" }} className="" to="/bookings">BOOKINGS</Link> */}
                    </div>
                ) : null
            }
        </nav >
    )
    return (
        <Navbar className="myNavBar" light expand="xl">
            <NavbarBrand onClick={() => history.push("/")}> <img style={{ cursor: "pointer", height: "50px" }} src={logo} alt="Logo" />  </NavbarBrand>
            <Collapse color="red" isOpen={isOpen} navbar>
                <Nav className="m-auto" navbar>
                    <NavItem>
                        <Link style={{ fontWeight: "bolder" }} className="NavLinksForNavBar" to="/">HOME</Link>
                    </NavItem>
                    <NavItem>
                        <Link style={{ fontWeight: "bolder" }} className="NavLinksForNavBar" to="/courier-with-us">COURIER WITH US</Link>
                    </NavItem>
                    {/* <NavItem>
                        <Link className="NavLinksForNavBar" to="/logistics">Logistics</Link>
                    </NavItem> */}
                    <NavItem>
                        <Link style={{ fontWeight: "bolder" }} className="NavLinksForNavBar" to="/processServing">PROCESS SERVING</Link>
                    </NavItem>
                    <NavItem>
                        <Link style={{ fontWeight: "bolder" }} className="NavLinksForNavBar" to="/tracking">TRACK</Link>
                    </NavItem>
                    {/* <NavItem>
                        <Link className="NavLinksForNavBar" to="/aboutUs">About Us</Link>
                    </NavItem>
                    <NavItem>
                        <Link className="NavLinksForNavBar" to="/contactUs">Contact Us</Link>
                    </NavItem> */}
                </Nav>
            </Collapse >

            <NavbarText>
                {
                    IsLogin ? (
                        <button
                            onClick={() => {
                                history.push("/signup")
                                setIsOpen(false)
                            }}
                            className="DashboardButton">
                            Go To Dashboard
                        </button>
                    ) : (
                            <>
                                <button
                                    onClick={() => {
                                        history.push("/login")
                                        setIsOpen(false)
                                    }}
                                    className="loginButton">
                                    {/* <img src={User} style={{ height: "17px", }} /> */}
                                    {
                                        IsWeb ? "Login" : ""
                                    }
                                </button>
                                <button
                                    onClick={() => {
                                        history.push("/signup")
                                        setIsOpen(false)
                                    }}
                                    className="signUpButton">
                                    Sign Up
                            </button>
                            </>
                        )
                }
                <NavbarToggler onClick={toggle} />

            </NavbarText>

        </Navbar >
    )


}



export default NavBarWeb
