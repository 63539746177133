import React, { useEffect } from 'react'
import Banner from '../commonComponets/Banner'
import PrivacyPolicyScreen from '../componets/privacyPolicy/PrivacyPolicyScreen'
const PrivacyPolicy:React.FC = (props) => {


    useEffect(()=>{
        //document.title = "Privacy Policy"
    },[])
    return (
        <>
            <Banner title="Privacy Policy" />
            <PrivacyPolicyScreen/>
        </>
    )
}

export default PrivacyPolicy
