import React, { useEffect } from 'react'
import Banner from '../commonComponets/Banner'
import CookiePolicyScreen from '../componets/cookiePolicy/CookiePolicyScreen'

const CookiePolicy:React.FC = (props) => {

    useEffect(()=>{
        //document.title = "Cookie Policy"
    },[])
    return (
        <>
            <Banner title="Cookie Policy" />
            <CookiePolicyScreen/>
        </>
    )
}

export default CookiePolicy
