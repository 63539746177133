import React, { useEffect } from 'react'
import Banner from '../commonComponets/Banner'
import LoginForm from '../componets/login/LoginForm'

const Login: React.FC = (props) => {


    useEffect(()=>{
        //document.title = "Login"
    },[])

    return (
        <div style={{background:"rgb(244, 244, 244)"}}>
            <Banner title="Sign In" />
            <LoginForm />
        </div>
    )
}

export default Login
