import Axios from "axios"
import { AppThunk, RootState } from "../root.reducer";
import * as ReducerActions from "../reducers"
import axios from "axios";
import { PricingResponse, AboutUsResponse, BookingObjINterface, BookingResponse, ContactUsContentInterface, ContactUsResponse, ContentResponse, GetBookingResponse, PolicyInterface, PolicyResponse, PageTitleAndImageResponse, HeadingAndListResponse, ParaResponse, GetNavbarContent, FooterSectionHeadingRequest, FooterStaticContent, FooterStaticContentRequest } from "../../interfaces";
import { Endpoint } from "../../constants";
import { toast } from "react-toastify";



export const GetDriveWithMeData = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<ContentResponse>(`${Endpoint}/api/v1/HomeServices/get?Type=DriveWithMe`);

        dispatch(ReducerActions.setDriveWithme(res.data.Content))
    } catch (error) {
        console.log(error)
    }
};

export const GetOurLocations = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<ContentResponse>(`${Endpoint}/api/v1/HomeServices/get?Type=OurLocations`);

        dispatch(ReducerActions.setOurLocations(res.data.Content))
    } catch (error) {
        console.log(error)
    }
};

export const GetWorkingWithkenan = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<ContentResponse>(`${Endpoint}/api/v1/HomeServices/get?Type=WorkingWithkenan`);

        dispatch(ReducerActions.setWorkingWithkenan(res.data.Content))
    } catch (error) {
        console.log(error)
    }
};

export const GetRequirements = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<ContentResponse>(`${Endpoint}/api/v1/HomeServices/get?Type=Requirements`);

        dispatch(ReducerActions.setRequirements(res.data.Content))
    } catch (error) {
        console.log(error)
    }
};

export const GetOurTeam = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<ContentResponse>(`${Endpoint}/api/v1/HomeServices/get?Type=OurTeam`);

        dispatch(ReducerActions.setOurTeam(res.data.Content))
    } catch (error) {
        console.log(error)
    }
};


export const GetHomeHeadings = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<ContentResponse>(`${Endpoint}/api/v1/HomeServices/get?Type=MainHeadings`);

        dispatch(ReducerActions.setHomeHeadings(res.data.Content[0]))
    } catch (error) {
        console.log(error)
    }
};

export const getHomeSmartHeadings = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<ContentResponse>(`${Endpoint}/api/v1/HomeServices/get?Type=SmartHeadings`);

        dispatch(ReducerActions.setHomeSmartHeadings(res.data.Content))
    } catch (error) {
        console.log(error)
    }
};

export const GetHomeBanner = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<ContentResponse>(`${Endpoint}/api/v1/HomeServices/get?Type=HomeBanner`);

        dispatch(ReducerActions.setHomeBanner(res.data.Content))
    } catch (error) {
        console.log(error)
    }
};


export const GetHomeServices = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<ContentResponse>(`${Endpoint}/api/v1/HomeServices/get?Type=HomeServices`);

        dispatch(ReducerActions.setHomeServices(res.data.Content))
    } catch (error) {
        console.log(error)
    }
};

export const GetAboutUsNewData = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<ContentResponse>(`${Endpoint}/api/v1/HomeServices/get?Type=AboutUs`);

        dispatch(ReducerActions.setAboutUsNewData(res.data.Content))
    } catch (error) {
        console.log(error)
    }
};

export const GetNewBlogData = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<ContentResponse>(`${Endpoint}/api/v1/HomeServices/get?Type=Blog`);

        dispatch(ReducerActions.setBlogNewData(res.data.Content))
    } catch (error) {
        console.log(error)
    }
};
export const GetServicesPage = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<ContentResponse>(`${Endpoint}/api/v1/HomeServices/get?Type=ServicesPage`);

        dispatch(ReducerActions.setServicesPage(res.data.Content))
    } catch (error) {
        console.log(error)
    }
};


export const GetLogistics = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<ContentResponse>(`${Endpoint}/api/v1/HomeServices/get?Type=Logistics`);

        dispatch(ReducerActions.setLogisiticsPage(res.data.Content))
    } catch (error) {
        console.log(error)
    }
};


export const GetDriverSignUp = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<ContentResponse>(`${Endpoint}/api/v1/HomeServices/get?Type=DriverSignUp`);

        dispatch(ReducerActions.setTempDev(res.data.Content))
    } catch (error) {
        console.log(error)
    }
};


export const GetBookingsList = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<ContentResponse>(`${Endpoint}/api/v1/HomeServices/get?Type=Bookings`);

        dispatch(ReducerActions.setBookingsPage(res.data.Content))
    } catch (error) {
        console.log(error)
    }
};




export const GetPolicy = (id: string): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<PolicyResponse>(`${Endpoint}/api/v1/Policies/get?Type=${id}`);

        dispatch(ReducerActions.setPolicy(res.data.Policy))
    } catch (error) {
        console.log(error)
    }
};


export const GetContactUs = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<ContactUsResponse>(`${Endpoint}/api/v1/HomeServices/contactus/get`);

        dispatch(ReducerActions.setContactUs(res.data.ContactUsContent))
    } catch (error) {
        console.log(error)
    }
};

export const GetAboutUs = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<AboutUsResponse>(`${Endpoint}/api/v1/HomeServices/aboutus/get`);

        dispatch(ReducerActions.setAboutUs(res.data.AboutUsContent))
    } catch (error) {
        console.log(error)
    }
};


export const GetCheckoutSteps = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<any>(`${Endpoint}/api/v1/HomeServices/checkoutSteps/get`);

        dispatch(ReducerActions.setCheckOutSteps(res.data.CheckoutStep))
    } catch (error) {
        console.log(error)
    }
};



export const PageTitleAndImage = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<PageTitleAndImageResponse>(`${Endpoint}/api/v1/HomeServices/pages/get`);

        dispatch(ReducerActions.setPageTitleAndImage(res.data.PageTitleAndImages))
    } catch (error) {
        console.log(error)
    }
};


export const GettingPageHeadingsAndTitles = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<HeadingAndListResponse>(`${Endpoint}/api/v1/ContactUs/headingAndListing/get`);

        dispatch(ReducerActions.setHeadingsAndListing(res.data.HeadingsAndLists))
    } catch (error) {
        console.log(error)
    }
};


export const GettingPara = (id: string): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<ParaResponse>(`${Endpoint}/api/v1/ContactUs/para/get?search=${id}`);
        return res.data.Para
    } catch (error) {
        console.log(error)
        const error2: any = error
        return error2.response.data

    }
};

export const GettingBlogInfo = (id: string): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<ContentResponse>(`${Endpoint}/api/v1/HomeServices/get?SmallHeading=${id}&Type=Blog`);
        return res.data.Content
    } catch (error) {
        console.log(error)
        const error2: any = error
        return error2.response.data.Content

    }
};

export const GetDistance = (LatFrom: number, LongFrom: number, LatTo: number, LongTo: number): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.post<any>(`${Endpoint}/api/v1/HomeServices/getDistance`, {
            "LatFrom": LatFrom,
            "LongFrom": LongFrom,
            "LatTo": LatTo,
            "LongTo": LongTo
        });
        return res.data.DistanceObj
    } catch (error) {
        console.log(error)
        return null

    }
};



export const GetPricingData = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<PricingResponse>(`${Endpoint}/api/v1/HomeServices/pricing/get`);
        dispatch(ReducerActions.setPricingData(res.data.PricingList))
    } catch (error) {
        console.log(error)
        const error2: any = error

        return error2.response.data.Content

    }
};


export const PickUpOrder = (Name: string, Phone: string, Email: string, Address: string, WhatToPickup: string): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.post<any>(`${Endpoint}/api/v1/HomeServices/order/pickup`, {
            "Name": Name,
            "Phone": Phone,
            "Email": Email,
            "Address": Address,
            "WhatToPickup": WhatToPickup
        });
        // toast.success(res.data.Msg)
        return res.data
    } catch (error) {
        console.log(error)
        // toast.error(error.response.data.Msg)
        const error2: any = error

        return error2.response.data



    }
};


export const DropoffOrder = (Name: string, Phone: string, Email: string, Address: string,): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.post<any>(`${Endpoint}/api/v1/HomeServices/order/dropoff`, {
            "Name": Name,
            "Phone": Phone,
            "Email": Email,
            "Address": Address,
        });
        // toast.success(res.data.Msg)
        return res.data
    } catch (error) {
        console.log(error)
        // toast.error(error.response.data.Msg)
        const error2: any = error

        return error2.response.data


    }
};


export const DocumentOrder = (
    Name: string,
    Phone: string,
    Email: string,
    Address: string,
    DefendantsName: string,
    DefendantsEmail: string,
    DefendantsPhone: string,
    DefendantsAddress: string,
    file: File[]
): AppThunk => async (dispatch, getState: () => RootState) => {

    try {

        const body = new FormData();
        body.append("Name", Name)
        body.append("Phone", Phone)
        body.append("Email", Email)
        body.append("Address", Address)
        body.append("DefendantsName", DefendantsName)
        body.append("DefendantsEmail", DefendantsEmail)
        body.append("DefendantsPhone", DefendantsPhone)
        body.append("DefendantsAddress", DefendantsAddress)

        for (let index = 0; index < file.length; index++) {
            const element = file[index];
            body.append(`file${index}`, element)

        }

        const res = await Axios.post<any>(`${Endpoint}/api/v1/HomeServices/order/documents`, body);
        return res.data
    } catch (error) {
        console.log(error)
        // toast.error(error.response.data.Msg)
        const error2: any = error

        return error2.response.data



    }
};


export const getFooterStaticContent = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<FooterStaticContentRequest>(`${Endpoint}/api/v1/HomeServices/footerStatic/get`);
        console.log(res.data)

        dispatch(ReducerActions.setFooterStaticContent(res.data.FooterStaticContent))
    } catch (error) {
        console.log(error)
    }
};


export const getFooterSectionHeadingRequest = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<FooterSectionHeadingRequest>(`${Endpoint}/api/v1/HomeServices/footerHeadings/get`);
        dispatch(ReducerActions.setFooterSectionHeading(res.data.FooterSectionHeadings))
    } catch (error) {
        console.log(error)
    }
};


export const getNavBarContent = (): AppThunk => async (dispatch, getState: () => RootState) => {

    try {
        const res = await Axios.get<GetNavbarContent>(`${Endpoint}/api/v1/HomeServices/navbar/get`);

        dispatch(ReducerActions.setNavbarCOntent(res.data.NavbarContent))
    } catch (error) {
        console.log(error)
    }
};


export const SubmitJobRequest = (
    JobTitle,
    Name,
    Email,
    Phone,
    Qualification,
    YearsOfExperience,
    Resume,
): AppThunk => async (dispatch, getState: () => RootState) => {

    try {

        const body = new FormData();

        body.append("JobTitle", JobTitle)
        body.append("Name", Name)
        body.append("Email", Email)
        body.append("PhoneNo", Phone)
        body.append("Qualification", Qualification)
        body.append("HowManyYearsOfExperience", YearsOfExperience)
        body.append("resume", Resume)

        const res = await Axios.post<any>(`${Endpoint}/api/v1/jobRequest/submit`, body);

        // dispatch(ReducerActions.setNavbarCOntent(res.data.NavbarContent))

        return ""
    } catch (error) {
        console.log(error)
        // toast.error(error.response.data.Msg)
        const error2: any = error

        return error2.response.data.Msg
    }
};