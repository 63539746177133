import React,{useEffect} from 'react'
import Banner from '../commonComponets/Banner'
import BlogsScren from '../componets/blogs/BlogsScreen'

const Blogs:React.FC = () => {

    useEffect(()=>{
        // //document.title = "About Us"
    },[])

    return (
        <>
            <Banner title="Blogs" />
            <BlogsScren/>
        </>
    )
}

export default Blogs
