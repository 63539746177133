import React, { useState, useEffect } from 'react'
import ServiceCard from '../../commonComponets/ServiceCard'
import { GoogleMapsInterface, RequestPickupDialogInterface, ServiceCardData, FromAddressToLatLong } from '../../interfaces'
import ServiceImg_1 from "../../assets/images/ser2-1.jpg"
import ServiceImg_2 from "../../assets/images/ser2-2.jpg"
import DialogWrapper from "../../commonComponets/DialogWrapper"
import { Button } from 'reactstrap'
import { CustomButton } from "../../commonComponets"
import { useHistory, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store/root.reducer";
import { baseOrange, basePurple } from '../../constants'
import { PortraitSharp } from '@material-ui/icons'
import { ServicesData } from '../services/ServicesScreen'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { toast } from 'react-toastify'
import Geocode from "react-geocode";
import * as Actions from "../../store/actions"
// import distance from 'google-distance-matrix'
import { CircularProgress } from '@material-ui/core'
import GooglePlacesCommon from '../../commonComponets/GooglePlacesCommon'

// distance.key("AIzaSyCsQslpRrfxR8vpUP8pLC7wOIpFNPropsI")


Geocode.setApiKey("AIzaSyCsQslpRrfxR8vpUP8pLC7wOIpFNPropsI");
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("es");
const BookingsListScreen: React.FC = () => {


    const dispatch = useDispatch()
    const history = useHistory()


    const [Step, setStep] = useState(1)
    const [IsLoading, setIsLoading] = useState(false)
    const [SendersName, setSendersName] = useState("")
    const [RecieversName, setRecieversName] = useState("")
    const [SendersEmail, setSendersEmail] = useState("")
    const [SendersPhoneNo, setSendersPhoneNo] = useState("")
    const [RecieversEmail, setRecieversEmail] = useState("")
    const [RecieversPhoneNo, setRecieversPhoneNo] = useState("")
    const [PickUpAddress, setPickUpAddress] = useState<any>("")
    const [DelieveryAddress, setDelieveryAddress] = useState<any>("")
    const [WhatToDeliever, setWhatToDeliever] = useState("")
    const [ServiceType, setServiceType] = useState("")

    const [TotalDistance, setTotalDistance] = useState<number>(0)
    const [TotalFee, setTotalFee] = useState<number>(0)
    const [DelievryAmount, setDelievryAmount] = useState<number>(0)
    const [HSTCharge, setHSTCharge] = useState<number>(0)
    const [DriverTip, setDriverTip] = useState<number>(0)

    const [FromLatLong, setFromLatLong] = useState<any>({})
    const [ToLatLong, setToLatLong] = useState<any>({})

    const [IsError, setIsError] = useState(false)

    const [IsRequestPickupOpen, setIsRequestPickupOpen] = useState<boolean>(false)

    const BookingsPage = useSelector((state: RootState) => state.Content.BookingsPage)
    const UserData = useSelector((state: RootState) => state.User.UserData)

    useEffect(() => {
        if (UserData) {
            setSendersName(UserData?.FirstName + " " + UserData?.LastName)
            setSendersEmail(UserData?.Email || "")
            setSendersPhoneNo(UserData?.PhoneNo || "")
            setPickUpAddress(UserData?.Address)
        }
    }, [UserData])

    useEffect(() => {
        if (sessionStorage.getItem("@Addresses")) {
            const address = JSON.parse(sessionStorage.getItem("@Addresses") || "");
            setPickUpAddress(address.AddressFrom)
            setDelieveryAddress(address.AddressTo)
        }
    }, [])
    useEffect(() => {
        if (sessionStorage.getItem("@OnGoingBooking")) {
            const newBookingData = JSON.parse(sessionStorage.getItem("@OnGoingBooking") || "");
            setWhatToDeliever(newBookingData.WhatToDeliever)
            setServiceType(newBookingData.ServiceType)
            setSendersName(newBookingData.SendersName)
            setSendersPhoneNo(newBookingData.SendersPhoneNo)
            setSendersEmail(newBookingData.SendersEmail)
            setPickUpAddress(newBookingData.PickUpAddress)
            setRecieversName(newBookingData.RecieversName)
            setRecieversPhoneNo(newBookingData.RecieversPhoneNo)
            setDelieveryAddress(newBookingData.DelieveryAddress)
            setRecieversEmail(newBookingData.RecieversEmail)
        }
    }, [])
    async function MakeAbookingNow() {
        try {
            setIsLoading(true)
            setIsError(false)
            if (
                !WhatToDeliever ||
                !ServiceType ||
                !SendersName ||
                // !SendersEmail ||
                !SendersPhoneNo ||
                !PickUpAddress ||
                !RecieversName ||
                // !RecieversEmail ||
                !RecieversPhoneNo ||
                !DelieveryAddress
            ) throw "Please select All the required fields!"

            const res1: FromAddressToLatLong = await Geocode.fromAddress(PickUpAddress)
            const res2: FromAddressToLatLong = await Geocode.fromAddress(DelieveryAddress)

            const location1 = {
                lat: res1?.results[0].geometry?.location?.lat,
                long: res1?.results[0].geometry?.location?.lng,
            }
            const location2 = {
                lat: res2?.results[0].geometry?.location?.lat,
                long: res2?.results[0].geometry?.location?.lng,
            }

            setFromLatLong(location1)
            setToLatLong(location2)

            let origins = [`${location1.lat},${location1.long}`];

            let destinations = [`${location2.lat},${location2.long}`];


            const distanceRes: any = await dispatch(Actions.GetDistance(location1.lat || 0, location1.long || 0, location2.lat || 0, location2.long || 0));
            let distanceInKM = distanceRes?.distance?.value || 0;
            distanceInKM = distanceInKM * 0.001
            distanceInKM = parseFloat(distanceInKM.toFixed(1))


            setTotalDistance(parseFloat(distanceInKM.toFixed(3)));


            let extraCost = distanceInKM * 1.11;

            let boxes = parseInt(ServiceType) || 0
            if (boxes > 3) {
                boxes = boxes - 3;
            }else{
                boxes = 0
            }
            const boxPrice = boxes * 0.50;
            extraCost = extraCost + boxPrice;
            const roundedValue = parseFloat(extraCost.toFixed(3));

            const HST = (13 / 100) * roundedValue
            const total = parseFloat(HST.toFixed(2)) + roundedValue
            setDelievryAmount(roundedValue)
            setHSTCharge(parseFloat(HST.toFixed(2)));
            setDriverTip(0);
            setTotalFee(parseFloat(total.toFixed(2)))

            setIsLoading(false);

            setStep(2)

            sessionStorage.setItem("@OnGoingBooking", JSON.stringify({
                SendersName,
                RecieversName,

                SendersEmail,
                RecieversEmail,
                SendersPhoneNo,
                RecieversPhoneNo,

                PickUpAddress,
                DelieveryAddress,

                WhatToDeliever,
                ServiceType,
            }))

        } catch (error) {
            setIsLoading(false)
            setIsError(true)
            toast.error(error)
        }
    }

    async function BookNowNow() {
        try {
            setIsLoading(true)
            setIsError(false)

            const body = {
                "DelieveryType": WhatToDeliever,
                "ServiceType": ServiceType,
                "IsRegisteredUser": false,
                "RegisteredUsersID": "",
                "SendersName": SendersName,
                "SendersPhoneNo": SendersPhoneNo,
                "SendersEmail": SendersEmail,
                "SendersAddress": PickUpAddress,
                "SendersLat": FromLatLong.lat,
                "SendersLong": FromLatLong.long,
                "PickUpDate": new Date().toISOString(),
                "RecieversName": RecieversName,
                "RecieversPhoneNo": RecieversPhoneNo,
                "RecieversAddress": DelieveryAddress,
                "RecieversLat": ToLatLong.lat,
                "RecieversLong": ToLatLong.long,
                "TotalDistance": TotalDistance,
                "TotalFee": TotalFee,
                "IsContactLessPickup": false,
                "ContactLessDelievery": false,
                "Notes": ""
            }


            const res69 = await dispatch(Actions.MakeAbooking(body))

            if (res69) throw res69
            // sessionStorage.setItem("@OnGoingBooking", JSON.stringify(body))
            toast.success("Booking successful, Please procedue to payment!")
            setIsLoading(false)
            history.push("/payment")
        } catch (error) {
            setIsLoading(false)
            setIsError(true)
            toast.error(error)
        }
    }



    return (
        <>
            <>
                {
                    Step === 1 ? (
                        <div style={{ height: "400px", padding: "0 10%" }} className="row justify-content-center pb-5">
                            <div className="col-lg-12 text-center">
                                <div >
                                    <div className="row ">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input
                                                    value={SendersName}
                                                    onChange={e => setSendersName(e.target.value)}
                                                    style={{ height: "54px" }} type="text" placeholder="Sender's Name *" required={true} className={`form-control ${IsError && !SendersName ? "errorInput" : ""}`} />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input
                                                    value={RecieversName}
                                                    onChange={e => setRecieversName(e.target.value)}
                                                    style={{ height: "54px" }} type="text" placeholder="Reciever's Name *" required={true} className={`form-control ${IsError && !RecieversName ? "errorInput" : ""}`} />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input
                                                    value={SendersEmail}
                                                    onChange={e => setSendersEmail(e.target.value)}
                                                    style={{ height: "54px" }} type="Email" placeholder="Sender's Email" required={true} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input
                                                    value={RecieversEmail}
                                                    onChange={e => setRecieversEmail(e.target.value)}
                                                    style={{ height: "54px" }} type="Email" placeholder="Reciever's Email" required={true} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input
                                                    value={SendersPhoneNo}
                                                    onChange={e => setSendersPhoneNo(e.target.value)}
                                                    style={{ height: "54px" }} max={11} type="number" placeholder="Sender's Phone *" required={true} className={`form-control ${IsError && !SendersPhoneNo ? "errorInput" : ""}`} />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input
                                                    value={RecieversPhoneNo}
                                                    onChange={e => setRecieversPhoneNo(e.target.value)}
                                                    style={{ height: "54px" }} max={11} type="number" placeholder="Reciever's Phone *" required={true} className={`form-control ${IsError && !RecieversPhoneNo ? "errorInput" : ""}`} />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className={`form-group `}>

                                                {/* <GooglePlacesAutocomplete
                                                    apiKey="AIzaSyCsQslpRrfxR8vpUP8pLC7wOIpFNPropsI"
                                                    autocompletionRequest={{
                                                        componentRestrictions: {
                                                            country: ["ca"],
                                                        }
                                                    }}
                                                    selectProps={
                                                        {
                                                            escapeClearsValue: true,
                                                            defaultOptions: {},
                                                            onChange: setPickUpAddress,
                                                            value: PickUpAddress,
                                                            placeholder: 'Search an Area',
                                                        }}
                                                /> */}
                                                <GooglePlacesCommon
                                                    placeholder="Search an pickup address"
                                                    value={PickUpAddress}
                                                    setValue={e => setPickUpAddress(e)}
                                                    IsError={IsError && !PickUpAddress}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className={`form-group  `}>
                                                {/* <GooglePlacesAutocomplete
                                                    apiKey="AIzaSyCsQslpRrfxR8vpUP8pLC7wOIpFNPropsI"
                                                    autocompletionRequest={{
                                                        componentRestrictions: {
                                                            country: ["ca"],
                                                        }
                                                    }}
                                                    selectProps={
                                                        {
                                                            escapeClearsValue: true,
                                                            defaultOptions: {},
                                                            onChange: setDelieveryAddress,
                                                            value: DelieveryAddress,
                                                            placeholder: 'Search an Area',
                                                        }}
                                                /> */}
                                                <GooglePlacesCommon
                                                    placeholder="Search an dropoff address"
                                                    value={DelieveryAddress}
                                                    setValue={e => setDelieveryAddress(e)}
                                                    IsError={IsError && !DelieveryAddress}

                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <select
                                                    value={WhatToDeliever}
                                                    onChange={e => setWhatToDeliever(e.target.value)}
                                                    style={{ height: "54px" }} placeholder="Delivery Type*" required={true} className={`form-control ${IsError && !WhatToDeliever ? "errorInput" : ""}`} >
                                                    <option disabled selected value="">Delivery Type*</option>
                                                    <option value="box">Box</option>
                                                    <option value="legal">Document</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input
                                                    value={ServiceType}
                                                    onChange={e => setServiceType(e.target.value)}
                                                    style={{ height: "54px" }} type="number" placeholder="How many Boxes/Documents *" required={true} className={`form-control ${IsError && !ServiceType ? "errorInput" : ""}`} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    ) : (

                            <div style={{ height: "400px", padding: "0 10%" }}>
                                <div className="">
                                    <div className="row justify-content-center ">
                                        <div className="col-xl-10 ">
                                            <div className="">
                                                <h3 className="text-capitalize">QUOTE</h3>
                                                <hr />
                                                <h4 style={{ fontWeight: "bold" }} >Total Distance:
                                                    <span style={{ float: "right", color: baseOrange, }} >{TotalDistance} Km</span>
                                                </h4>
                                                <h4 style={{ fontWeight: "bold" }} className="pt-3" >Delivey Amount:
                                                    <span style={{ float: "right", color: baseOrange, }}>${DelievryAmount}</span>
                                                </h4>
                                                <h4 style={{ fontWeight: "bold" }} className="pt-3" >HST:
                                                    <span style={{ float: "right", color: baseOrange, }}>${HSTCharge}</span>
                                                </h4>
                                                {/* <h4 style={{ fontWeight: "bold" }} className="pt-3" >Driver Tip:
                                                    <span style={{ float: "right", color: baseOrange, }}>${DriverTip}</span>
                                                </h4> */}
                                                <h4 style={{ fontWeight: "bold" }} className="pt-3" >Total:
                                                    <span style={{ float: "right", color: baseOrange, }}>${TotalFee}</span>
                                                </h4>

                                                <p
                                                    className="pt-3"
                                                    style={{ lineHeight: "26px", fontSize: "16px", fontWeight: 550 }}
                                                >
                                                    Note: Pick-up &amp; delivery prices are estimates only and do not reflect variations due to discounts, traffic delays, or other factors. Flat rates and minimum fees may apply.
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )
                }
            </>
            <>
                {
                    IsLoading ? <p style={{ textAlign: "center",marginTop:"30px" }}>
                        <CircularProgress />
                    </p> : Step === 1 ? (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <CustomButton
                                varient="solid"
                                className="mt-3"
                                backgorundColor={basePurple}
                                width="200px"
                                height="50px"
                                hoverbackgroundColor={baseOrange}
                                styles={{ fontSize: "18px", fontWeight: "bold", margin: "20px 10%" }}
                                noFilter
                                onClick={() => {
                                    // setStep(2)
                                    MakeAbookingNow()
                                }}
                            >
                                Book Now
                        </CustomButton>
                        </div>

                    ) :
                            // <CustomButton onClick={() => {
                            //     BookNowNow()
                            // }}
                            //     varient="solid" >
                            //         Book Now
                            //     </CustomButton>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <CustomButton
                                    varient="solid"
                                    className="mt-3"
                                    backgorundColor={basePurple}
                                    width="200px"
                                    height="50px"
                                    hoverbackgroundColor={baseOrange}
                                    styles={{ fontSize: "18px", fontWeight: "bold", margin: "20px 10%" }}
                                    noFilter
                                    onClick={() => {
                                        BookNowNow()
                                    }}
                                >
                                    Book Now
                 </CustomButton>
                            </div>
                }
            </>

            {
                BookingsPage.map((value, index) => {
                    return <ServiceCard index={index + 1} Data={{
                        Desc: value.Desc,
                        ImageALT: value.LargeHeading,
                        Img: value.Image_path,
                        LargeHeading: value.LargeHeading,
                        SmallHeading: value.SmallHeading,
                        ActionButtonAction: () => setIsRequestPickupOpen(true),
                        ActionButtonText: "Book Now",
                        // ActionButtonAction2: () => history.push("/estimate"),
                        // ActionButtonText2:"Get Estimate"
                    }} key={index + 1} />
                })
            }

            {/* <RequestPickupDialog
                open={IsRequestPickupOpen}
                closeDialog={() => setIsRequestPickupOpen(false)}
            /> */}
        </>
    )
}

export default BookingsListScreen
