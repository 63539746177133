import React from 'react'
import { baseOrange, AndroidApp } from '../../constants'
import appleStoreIcon from "../../assets/icons/apple.svg";
import playStoreIcon from "../../assets/icons/google.svg";
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/root.reducer';

const ReadyToRide: React.FC<{ IsTemp?: boolean }> = (props) => {

    const FooterStaticContent = useSelector((state: RootState) => state.Content.FooterStaticContent)



    return (
        <div className="pb-3 pt-3" style={{ backgroundColor: baseOrange }} >
            <div className="container d-flex align-items-center justiy-content-center flex-column" >
                <h2 style={{ color: "white", marginTop: "10px", textAlign: "center" }}>
                    Ready To {props.IsTemp ? "Drive" : "Courier"} ?
                </h2>
                <div className="mt-2 text-center" >
                    <a target="blank" href={FooterStaticContent?.IOSAppLink}><img style={{ height: "40px", margin: "10px", cursor: "pointer" }} src={appleStoreIcon} /></a>
                    <a target="blank" href={FooterStaticContent?.AndroidAppLink}><img style={{ height: "40px", margin: "10px", cursor: "pointer" }} src={playStoreIcon} /></a>
                </div>
                <p style={{ color: "white", marginTop: "20px" }} >
                    {
                        props.IsTemp ? (
                            <>Download The Future Driver Apps Today</>
                        ) : (
                                <>
                                    Or <Link to="/becomeDriver" style={{ color: "white", textDecoration: "underline" }} >Sign Up</Link> to start driving.
                           </>
                            )
                    }
                </p>
            </div>
        </div>
    )
}

export default ReadyToRide
