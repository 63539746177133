import React, { useEffect } from 'react'
import Banner from '../commonComponets/Banner'
import ForgotPasswordScreen from '../componets/forgotpassword/ForgotPasswordScreen'

const ForgotPassword: React.FC = (props) => {
    useEffect(()=>{
        //document.title = "Forget Password"
    },[])

    return (
        <>
            <Banner title="Forgot Password"/>
            <ForgotPasswordScreen/>
        </>
    )
}

export default ForgotPassword
