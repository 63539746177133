import React, { useState, useEffect } from 'react'
import Banner from '../commonComponets/Banner'
import BlogDetailsScreen from '../componets/blogs/BlogDetailsScreen'
import { useSelector, useDispatch } from "react-redux"
import * as Actions from "../store/actions"
import { RootState } from '../store/root.reducer';
import parse from 'html-react-parser';
import { CircularProgress } from '@material-ui/core';
import { useParams } from "react-router-dom"
 


const BlogDetails = () => {

    const dispatch = useDispatch()

    const { id } = useParams<{ id: string }>()

    const [Data, setData] = useState<any>(null)

    useEffect(() => {
        getData()
    }, [])


    async function getData() {
        try {
            const res: any = await dispatch(Actions.GettingBlogInfo(id))
            if (res.length > 0) {
                setData(res[0])
            }
        } catch (error) {
            setData({})
        }
    }

    if (Data == null) return (
        <div style={{ width: "100%", height: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
        </div>
    )
    return (
        <div>
            <Banner Image={Data?.Image_path} title={Data?.LargeHeading || "Blogs"} />
            <BlogDetailsScreen Text={Data?.Desc || "Not Found"} />
        </div>
    )
}

export default BlogDetails
