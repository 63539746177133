import React, { useEffect } from 'react'
import ServiceCard from '../../commonComponets/ServiceCard'
import ServiceImg_1 from "../../assets/images/ser2-1.jpg"
import ServiceImg_2 from "../../assets/images/ser2-2.jpg"
import ServiceImg_3 from "../../assets/images/ser2-3.jpg"
import ServiceImg_4 from "../../assets/images/ser2-4.jpg"
import ServiceImg_5 from "../../assets/images/ser2-5.jpg"
import ServiceImg_6 from "../../assets/images/ser2-6.jpg"
import { ServiceCardData } from '../../interfaces'
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../../store/root.reducer"
import * as Actions from "../../store/actions"

export const ServicesData: ServiceCardData[] = [
    {
        SmallHeading: "SHIPPING WITH Future",
        LargeHeading: "Rush".toUpperCase(),
        Desc: "From booking to communications to payment, Future helps you transport freight faster, cheaper, safer, and easier so you can stay focused on your business.",
        Img: ServiceImg_1,
        ImageALT: "Shipper_HP",

    },
    {
        SmallHeading: "SHIPPING WITH Future",
        LargeHeading: "sameday - anytime".toUpperCase(),
        Desc: "Future's transport marketplace gives you access to thousands of feedback-rated providers who compete for your business, so you can pick your price and ship with confidence.",
        Img: ServiceImg_2,
        ImageALT: "Carrier_HP",

    },
    {
        SmallHeading: "SHIPPING WITH Future",
        LargeHeading: "overnight".toUpperCase(),
        Desc: "Future's transport marketplace gives you access to thousands of feedback-rated providers who compete for your business, so you can pick your price and ship with confidence.",
        Img: ServiceImg_4,
        ImageALT: "Business_HP",
    },
    {
        SmallHeading: "SHIPPING WITH Future",
        LargeHeading: "Pickup Service".toUpperCase(),
        Desc: "Future's transport marketplace gives you access to thousands of feedback-rated providers who compete for your business, so you can pick your price and ship with confidence.",
        Img: ServiceImg_3,
        ImageALT: "Business_HP",

    },

    // {
    //     SmallHeading: "SHIPPING WITH KENAN",
    //     LargeHeading: "International Courier",
    //     Desc: "Kenan's transport marketplace gives you access to thousands of feedback-rated providers who compete for your business, so you can pick your price and ship with confidence.",
    //     Img: ServiceImg_5,
    //     ImageALT: "Business_HP",

    // },
    // {
    //     SmallHeading: "SHIPPING WITH KENAN",
    //     LargeHeading: "Warehouseing",
    //     Desc: "Kenan's transport marketplace gives you access to thousands of feedback-rated providers who compete for your business, so you can pick your price and ship with confidence.",
    //     Img: ServiceImg_6,
    //     ImageALT: "Business_HP",

    // },
]

const LogisticsScreen: React.FC = (props) => {

    const dispatch = useDispatch()

    const LogisiticsPage = useSelector((state: RootState) => state.Content.LogisiticsPage)

    useEffect(() => {
        // dispatch(Actions.GetLogistics())
    }, [])
    return (
        <>
            {
                LogisiticsPage.map((value, index) => {
                    return <ServiceCard
                        isLogistics={true}
                        index={index}
                        Data={{
                            Desc: value.Desc,
                            ImageALT: value.LargeHeading,
                            Img: value.Image_path,
                            LargeHeading: value.LargeHeading,
                            SmallHeading: value.SmallHeading,


                        }} key={index} />
                })
            }
        </>
    )
}

export default LogisticsScreen
